import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Card, Form, Container as BSContainer } from "react-bootstrap";
import { Buffer } from "buffer";

export const DataSource = (props) => {
  const [dataSourceUrl, setDataSourceUrl] = useState("");
  const dataSourceUrlRef = useRef("");
  dataSourceUrlRef.current = dataSourceUrl;

  const [dataSourceTitle, setDataSourceTitle] = useState("");
  const dataSourceTitleRef = useRef("");
  dataSourceTitleRef.current = dataSourceTitle;

  const [initialLoad, setInitialLoad] = useState(true);

  const [dataSourceSelectedOption, setDataSourceSelectedOption] = useState(-1);
  const dataSourceSelectedOptionRef = useRef(-1);
  dataSourceSelectedOptionRef.current = dataSourceSelectedOption;

  const [dataSourceEndpoint, setDataSourceEndpoint] = useState("");
  const dataSourceEndpointRef = useRef("");
  dataSourceEndpointRef.current = dataSourceEndpoint;

  const getDataSource = async (event) => {
    const reader = new FileReader();
    await reader.addEventListener("load", () => {
      const uploaded_datasource = reader.result;
      const base64result = Buffer.from(uploaded_datasource).toString("base64");
      setDataSourceUrl(base64result);
      setDataSourceTitle(event.target.files[0].name);
    });
    await reader.readAsBinaryString(event.target.files[0]);
  };

  useEffect(() => {
    if (initialLoad) {
      if (props.initialValuesFromAPI !== undefined && props.initialValuesFromAPI.length !== 0) {
        let blockValues = props.initialValuesFromAPI.find((iv) => iv.id === "canvasDataSourceId");
        if (blockValues !== undefined) {
          let ourBlockValues = blockValues.inputs[0];
          if (blockValues.blockType === "dstab") {
            for (const [key, value] of Object.entries(ourBlockValues[1])) {
              if (key === "dataSourceUrl") {
                dataSourceSelectedOptionRef.current = 1;
                setDataSourceSelectedOption(1);
                dataSourceUrlRef.current = value;
                setDataSourceUrl(value);
              } else if (key === "dataSourceTitle") {
                dataSourceTitleRef.current = value;
                setDataSourceTitle(value);
              } else if (key === "dataSourceEndpoint") {
                dataSourceSelectedOptionRef.current = 2;
                setDataSourceSelectedOption(2);
                dataSourceEndpointRef.current = value;
                setDataSourceEndpoint(value);
              }
            }
            props.setContentHandler(dataSourceTitleRef.current, dataSourceUrlRef.current);
          }
        }
        setInitialLoad(false);
      }
    }
  }, [props.initialValuesFromAPI]);

  const dataSourceSelectedOptionSelected = (event) => {
    dataSourceEndpointRef.current = "";
    setDataSourceEndpoint("");
    dataSourceTitleRef.current = "";
    setDataSourceTitle("");
    dataSourceUrlRef.current = "";
    setDataSourceUrl("");

    dataSourceSelectedOptionRef.current = event.target.selectedIndex;
    setDataSourceSelectedOption(event.target.selectedIndex);
  };

  return (
    <Card>
      <BSContainer>
        <Card className="my-1 childContent dataSourceTable" id="canvasDataSourceId" key="canvasDataSourceKey">
          <BSContainer>
            <Form.Group className="mt-3 mb-2">
              <Form.Label>Data Source</Form.Label>
              <Form.Control
                className={`${props.isProd ? "mb-1 blockId d-none" : "mb-1 blockId"}`}
                readOnly
                type="datasource"
                value="canvasDataSourceId"
                placeholder="Enter Block ID"
              />
              <Form.Control
                className={`${props.isProd ? "mb-1 blockKey d-none" : "mb-1 blockKey"}`}
                readOnly
                type="datasource"
                value="canvasDataSourceKey"
                placeholder="Enter Block Key"
              />
              <Form.Select value={dataSourceSelectedOptionRef.current} onChange={dataSourceSelectedOptionSelected} type="fieldselect" className="fieldselect">
                <option value="-1">Select One</option>
                <option value="1">File</option>
                <option value="2">Endpoint</option>
              </Form.Select>
              <Form.Group className="mb-3 mappingInput">
                <div className="mappingFields" id="1_mappingInput">
                  <div id="dsContain">
                    {dataSourceSelectedOptionRef.current === 1 ? (
                      <>
                        <Form.Control type="file" id="dataSourceFile" onChange={getDataSource} className="valueField" accept=".json" />
                        <Form.Control type="fieldname" id="dataSourceTitle" defaultValue={dataSourceTitle} className="valueField" readOnly />
                        <Form.Control
                          type="input"
                          id="dataSourceUrl"
                          value={dataSourceUrl}
                          className={`${props.isProd ? "valueField d-none" : "valueField"}`}
                          readOnly
                        />
                      </>
                    ) : dataSourceSelectedOptionRef.current === 2 ? (
                      <Form.Control type="input" id="dataSourceEndpoint" defaultValue={dataSourceEndpoint} className="valueField" />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form.Group>
            </Form.Group>
          </BSContainer>
        </Card>
      </BSContainer>
    </Card>
  );
};
