import React, { useState, useEffect } from "react";
import { Form, Button, Container as BSContainer } from "react-bootstrap";

export const SearchBlock = (props) => {
  const [vin, setVin] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  const handleChange = (event) => {
    setVin(event.target.value);
  };

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  }, []);

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For SearchBlock", props.id);
  }

  useEffect(() => {
    if (initialLoad) {
      if (props.initialSearchValue !== null && props.initialSearchValue !== "" && props.initialSearchValue !== undefined) {
        setVin(props.initialSearchValue);
        setInitialLoad(false);
      }
    }
  }, [props.searchParams, initialLoad]);

  return (
    <BSContainer className="mt-2 p-0 d-flex" id={props.keyId} role="search">
      <Form.Control
        value={vin}
        onChange={handleChange}
        className="form-control me-2"
        type="search"
        placeholder={props.mapping.length !== 0 ? props.mapping[0][1].searchText : ""}
        aria-label="Search"
      />
      <Button onClick={props.handleClick.bind(this, vin)} className="btn btn-outline-success btn-search ml-auto col-auto" type="submit">
        {props.mapping.length !== 0 ? props.mapping[0][1].buttonTitle : ""}
      </Button>
    </BSContainer>
  );
};
