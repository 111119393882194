import { authService } from "./index.js";
import axios from "axios";

const requestOptions = async (scopes, extraHeaders) => {
    const authHeaders = await authService.getAuthHeaders(scopes);
    return {
        headers: { ...authHeaders, ...extraHeaders },
    };
};

const get = async (path, scopes, extraHeaders, isAPI = false) => {
    let url = `${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`;
    if (isAPI) {
        url = path;
    }

    return await axios.get(url, await requestOptions(scopes, extraHeaders));
};

const post = async (path, body, scopes, extraHeaders) => {
    return await axios.post(
        `${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`,
        body,
        await requestOptions(scopes, extraHeaders)
    );
};

const put = async (path, body, scopes, extraHeaders) => {
    return await axios.put(
        `${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`,
        body,
        await requestOptions(scopes, extraHeaders)
    );
};

export class WebAppConfigs {
    /**
     * Gets data via input if configured to use API.
     * This should be called immediately when the App renders.
     * @returns
     */
    static async getData(endpoint) {
        const response = await get(endpoint, undefined, undefined, true);
        return response.data;
    }

    /**
     * Gets the app config blob with the given config ID and blob type.
     * For use by Admin role in editing an app configuration/layout.
     * @param {*} confId
     * @param {*} blobType
     * @returns
     */
    static async get(pageId, confId, blobType) {
        const response = await get(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${confId}/${blobType}`
        );
        return response.data;
    }

    /**
     * Updates the app config (e.g., layout) with the new value.
     * For use by Admin role in editing an app configuration/layout.
     * @param {*} updatedConfig
     * @returns
     */
    static async update(pageId, updatedConfig) {
        const response = await put(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${updatedConfig.definitionId}`,
            updatedConfig
        );
        return response.data;
    }

    /**
     * Updates the UILayouts with the new value.
     * For use by Admin role in editing an app layout.
     * @param {*} relationalId, updatedConfig
     * @returns
     */
    static async updateUILayout(pageId, relationalId, updatedConfig) {
        const response = await put(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${relationalId}/UILayouts`,
            updatedConfig
        );
        return response.data;
    }

    /**
     * Updates the UIConfigs with the new value.
     * For use by Admin role in editing an app configurations.
     * @param {*} relationalId, updatedConfig
     * @returns
     */
    static async updateUIConfig(pageId, relationalId, updatedConfig) {
        const response = await put(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${relationalId}/UIConfigs`,
            updatedConfig
        );
        return response.data;
    }
}
