import React, { useEffect } from "react";

export const ButtonsBlock = (props) => {
  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For ButtonsBlock", props.id)
  }

  const contentToDisplay = props.mapping.map((button, idx) => {
    let content;
    if (button[1].buttonUrl.length === 0) {
      content = (
        <button type="button" key={idx + "_button"} className="btn btn-outline-secondary me-1 disabled">
          {button[1].buttonName}
        </button>
      );
    } else {
      let url;
      try {
        url = new URL(button[1].buttonUrl);
      } catch (ex) {
        console.warn("Invalid URL for button", button[1].buttonName, ex);
        url = button[1].buttonUrl;
      }
      content = (
        <a href={url} key={idx + "_button"} rel="noopener noreferrer" target="_blank" className="btn btn-outline-secondary me-1">
          {button[1].buttonName}
        </a>
      );
    }
    return content;
  });

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  }, []);

  return (
    <div id={props.keyId} className="d-flex-inline justify-content-around mt-2">
      {contentToDisplay}
    </div>
  );
};
