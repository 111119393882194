import React, { useState, useRef } from "react";
import { Form, Button, Container as BSContainer, Row, Col } from "react-bootstrap";

export const MappingInput = (props) => {
  const [freeFormSelectedOption, setFreeFormselectedOption] = useState("-1");
  const freeFormSelectedOptionSelected = (selected) => {
    setFreeFormselectedOption(selected.target.value);
  };

  const [fontColorSelectedOption, setFontColorSelectedOption] = useState("#000000");
  const fontColorSelectedOptionSelected = (selected) => {
    setFontColorSelectedOption(selected.target.value);
  };

  const [fontSizeSelectedOption, setFontSizeSelectedOption] = useState("display-8");
  const setFontSizeSelectedOptionSelected = (selected) => {
    setFontSizeSelectedOption(selected.target.value);
  };

  const [backgroundColorSelectedOption, setBackgroundColorSelectedOption] = useState("#ffffff");
  const backgroundColorSelectedOptionSelected = (selected) => {
    setBackgroundColorSelectedOption(selected.target.value);
  };

  const [alignmentSelectedOption, setAlignmentSelectedOption] = useState("justify-content-center");
  const alignmentSelectedOptionSelected = (selected) => {
    setAlignmentSelectedOption(selected.target.value);
  };

  const [borderTypeSelectedOption, setBorderTypeSelectedOption] = useState("");
  const borderTypeSelectedOptionSelected = (selected) => {
    borderTypeSelectedOptionRef.current = selected.target.value;
    setBorderTypeSelectedOption(selected.target.value);
  };
  const borderTypeSelectedOptionRef = useRef;
  borderTypeSelectedOptionRef.current = borderTypeSelectedOption;

  const [paddingSelectedOption, setPaddingSelectedOption] = useState("0");
  const paddingSelectedOptionSelected = (selected) => {
    setPaddingSelectedOption(selected.target.value);
  };

  const [textJustificationOption, setTextJustificationOption] = useState("center");
  const textJustificationOptionSelected = (selected) => {
    textJustificationOptionRef.current = selected.target.value;
    setTextJustificationOption(selected.target.value);
  };
  const textJustificationOptionRef = useRef("center");
  textJustificationOptionRef.current = textJustificationOption;

  const [imageUrl, setImageUrl] = useState("");

  const [imageTitle, setImageTitle] = useState("");

  const getImage = async (event) => {
    const reader = new FileReader();
    await reader.addEventListener("load", () => {
      const uploaded_image = reader.result;
      setImageUrl(uploaded_image);
    });
    await reader.readAsDataURL(event.target.files[0]);
  };

  const [textInputValue, setTextInputValue] = useState("");
  const [fieldMapPathValue, setFieldMapPathValue] = useState("");
  const [columnNameValue, setColumnNameValue] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [buttonUrl, setButtonUrl] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [isUrl, setIsUrl] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isQueryParam, setIsQueryParam] = useState(false);
  const [queryParam, setQueryParam] = useState("");
  const [hlTitle, setHlTitle] = useState("");

  const handleTick = () => {
    setIsQueryParam(!isQueryParam);
    if (!isQueryParam) {
      setQueryParam("");
    }
  };

  const handleChange = () => {
    setIsUrl(!isUrl);
    if (!isUrl) {
      setHlTitle("");
    }
  };

  if (initialLoad) {
    if (props.initialValuesFromAPI !== undefined) {
      let blockValues = props.initialValuesFromAPI.find((iv) => iv.id === String(props.blockId));
      if (blockValues !== undefined) {
        let ourBlockValues = blockValues.inputs.find((bv) => bv[0] === props.id);
        if (
          blockValues.blockType === "buttons" ||
          blockValues.blockType === "table" ||
          blockValues.blockType === "informational" ||
          blockValues.blockType === "search"
        ) {
          for (const [key, value] of Object.entries(ourBlockValues[1])) {
            if (key === "fieldmappath") {
              setFieldMapPathValue(value);
            } else if (key === "columnName") {
              setColumnNameValue(value);
            } else if (key === "buttonName") {
              setButtonName(value);
            } else if (key === "buttonUrl") {
              setButtonUrl(value);
            } else if (key === "buttonTitle") {
              setButtonTitle(value);
            } else if (key === "searchText") {
              setSearchText(value);
            } else if (key === "fieldName") {
              setFieldName(value);
            } else if (key === "isUrl") {
              setIsUrl(value);
            } else if (key === "isQueryParam") {
              setIsQueryParam(value);
            } else if (key === "queryParam") {
              setQueryParam(value);
            } else if (key === "hlTitle") {
              setHlTitle(value);
            } else if (key === "alignmentInput") {
              setTextJustificationOption(value);
            }
          }
        } else if (blockValues.blockType === "freeform") {
          for (const [key, value] of Object.entries(ourBlockValues[1])) {
            if (key === "inputType") {
              if (value === "textContain") {
                setFreeFormselectedOption("1");
              } else if (value === "imageContain") {
                setFreeFormselectedOption("2");
              }
            }
            if (key === "textInput") {
              setTextInputValue(value);
            } else if (key === "alignmentInput") {
              setAlignmentSelectedOption(value);
            } else if (key === "backgroundColorInput") {
              setBackgroundColorSelectedOption(value);
            } else if (key === "borderTypeInput") {
              setBorderTypeSelectedOption(value);
            } else if (key === "fontColorInput") {
              setFontColorSelectedOption(value);
            } else if (key === "fontSizeInput") {
              setFontSizeSelectedOption(value);
            } else if (key === "paddingSelectInput") {
              setPaddingSelectedOption(value);
            } else if (key === "imageTitle") {
              setImageTitle(value);
            } else if (key === "imageUrl") {
              setImageUrl(value);
            }
          }
        }
      }
    }
    setInitialLoad(false);
  }

  return (
    <>
      <Row id={props.id} className="px-0 pl-1 pb-1 mappingFields">
        {props.type === "blockFreeForm" ? (
          <>
            <Col sm="auto">
              <Form.Select value={freeFormSelectedOption} onChange={freeFormSelectedOptionSelected} type="fieldselect" className="fieldselect">
                <option value="-1">Select One</option>
                <option value="1">Text</option>
                <option value="2">Image</option>
              </Form.Select>
            </Col>
            <Col xl={7} sm="auto">
              {freeFormSelectedOption === "2" ? (
                <BSContainer id="imageContain">
                  <Row className="py-2">
                    <Form.Control type="file" id="imageFile" onChange={getImage} className="valueField" accept="image/jpeg, image/png, image/jpg" />
                    <Form.Control type="fieldname" id="imageTitle" defaultValue={imageTitle} className="valueField" placeholder="Enter Image Title" />
                    <Form.Control id="imageUrl" type="input" value={imageUrl} className="valueField" readOnly />
                  </Row>
                  <Row className="py-2">
                    <Col>
                      <Form.Label htmlFor="backgroundColorInput">Background: </Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        type="color"
                        className="valueField"
                        onChange={backgroundColorSelectedOptionSelected}
                        value={backgroundColorSelectedOption}
                        id="backgroundColorInput"
                        title="Choose your color"
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Alignment: </Col>
                    <Col>
                      <Form.Select
                        value={alignmentSelectedOption}
                        onChange={alignmentSelectedOptionSelected}
                        id="alignmentInput"
                        type="fieldselect"
                        className="valueField"
                      >
                        <option value="justify-content-start">Left</option>
                        <option value="justify-content-center">Middle (Default)</option>
                        <option value="justify-content-end">Right</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Border: </Col>
                    <Col>
                      <Form.Select
                        value={borderTypeSelectedOption}
                        onChange={borderTypeSelectedOptionSelected}
                        id="borderTypeInput"
                        type="fieldselect"
                        className="valueField"
                      >
                        <option value="">None (Default)</option>
                        <option value="border rounded">Rounded</option>
                        <option value="border square">Square</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Padding: </Col>
                    <Col>
                      <Form.Control
                        className="valueField"
                        type="number"
                        id="paddingSelectInput"
                        value={paddingSelectedOption}
                        onChange={paddingSelectedOptionSelected}
                        name="paddingSelectInput"
                        min="0"
                        max="5"
                      />
                    </Col>
                  </Row>
                </BSContainer>
              ) : freeFormSelectedOption === "1" ? (
                <BSContainer id="textContain">
                  <Row>
                    <Col>Text</Col>
                    <Col>
                      <Form.Control type="fieldmappath" id="textInput" defaultValue={textInputValue} className="valueField" placeholder="Enter Text" />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>
                      <Form.Label htmlFor="fontColorInput">Font Color: </Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        type="color"
                        className="valueField"
                        onChange={fontColorSelectedOptionSelected}
                        value={fontColorSelectedOption}
                        id="fontColorInput"
                        title="Choose your color"
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Font Size: </Col>
                    <Col>
                      <Form.Select
                        value={fontSizeSelectedOption}
                        onChange={setFontSizeSelectedOptionSelected}
                        id="fontSizeInput"
                        type="fieldselect"
                        className="valueField"
                      >
                        <option value="display-8">Normal (Default)</option>
                        <option value="display-4">Medium</option>
                        <option value="display-1">Large</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>
                      <Form.Label htmlFor="backgroundColorInput">Background: </Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        type="color"
                        className="valueField"
                        onChange={backgroundColorSelectedOptionSelected}
                        value={backgroundColorSelectedOption}
                        id="backgroundColorInput"
                        title="Choose your color"
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Alignment: </Col>
                    <Col>
                      <Form.Select
                        value={alignmentSelectedOption}
                        onChange={alignmentSelectedOptionSelected}
                        id="alignmentInput"
                        type="fieldselect"
                        className="valueField"
                      >
                        <option value="justify-content-start">Left</option>
                        <option value="justify-content-center">Middle (Default)</option>
                        <option value="justify-content-end">Right</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Border: </Col>
                    <Col>
                      <Form.Select
                        value={borderTypeSelectedOption}
                        onChange={borderTypeSelectedOptionSelected}
                        id="borderTypeInput"
                        type="fieldselect"
                        className="valueField"
                      >
                        <option value="">None (Default)</option>
                        <option value="border rounded">Rounded</option>
                        <option value="border square">Square</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>Padding: </Col>
                    <Col>
                      <Form.Control
                        className="valueField"
                        type="number"
                        id="paddingSelectInput"
                        value={paddingSelectedOption}
                        onChange={paddingSelectedOptionSelected}
                        name="paddingSelectInput"
                        min="0"
                        max="5"
                      />
                    </Col>
                  </Row>
                </BSContainer>
              ) : (
                <></>
              )}
            </Col>
          </>
        ) : props.type === "blockSearch" ? (
          <BSContainer id="searchContain" className="py-2">
            <Col sm="auto">
              <Form.Control id="buttonTitle" type="input" className="valueField mb-1" defaultValue={buttonTitle} placeholder="Enter Button Title" />
            </Col>
            <Col sm="auto">
              <Form.Control id="searchText" type="input" className="valueField" defaultValue={searchText} placeholder="Enter Search Title" />
            </Col>
            <Col sm="auto">
              <Form.Check id="isQueryParam" type="checkbox" checked={isQueryParam} onChange={handleTick} className="valueField" label="Is Query Param?" />
            </Col>
            {isQueryParam ? (
              <Col sm="auto">
                <Form.Control id="queryParam" type="input" className="valueField" defaultValue={queryParam} placeholder="Enter Query String" />
              </Col>
            ) : (
              <></>
            )}
          </BSContainer>
        ) : props.type === "blockButtons" ? (
          <BSContainer id="buttonContain" className="py-2">
            <Col sm="auto">
              <Form.Control id="buttonName" type="input" className="valueField mb-1" defaultValue={buttonName} placeholder="Enter Button Name" />
            </Col>
            <Col sm="auto">
              <Form.Control id="buttonUrl" type="input" className="valueField" defaultValue={buttonUrl} placeholder="Enter Button URL" />
              {/* <Form.Control type="fieldname" className="fieldname" placeholder="Enter Button Title" /> */}
            </Col>
          </BSContainer>
        ) : props.type === "blockInformational" ? (
          <BSContainer id="informationalContain" className="py-2">
            <Col sm="auto">
              <Form.Control id="fieldmappath" type="input" defaultValue={fieldMapPathValue} className="valueField mb-1" placeholder="Enter Path in Source" />
            </Col>
            <Col sm="auto">
              <Form.Control id="fieldName" type="input" defaultValue={fieldName} className="valueField" placeholder="Enter Field Name" />
            </Col>
          </BSContainer>
        ) : props.type === "blockTable" ? (
          <BSContainer id="tableContain" className="py-2">
            <Col sm="auto">
              <Form.Control id="fieldmappath" type="input" defaultValue={fieldMapPathValue} className="valueField mb-1" placeholder="Enter Path in Source" />
            </Col>
            <Col sm="auto">
              <Form.Control id="columnName" type="input" defaultValue={columnNameValue} className="valueField" placeholder="Enter Column Name" />
            </Col>
            <Col>Alignment: </Col>
            <Col>
              <Form.Select
                value={textJustificationOption}
                onChange={textJustificationOptionSelected}
                id="alignmentInput"
                type="fieldselect"
                className="valueField"
              >
                <option value="left">Left</option>
                <option value="center">Middle (Default)</option>
                <option value="right">Right</option>
              </Form.Select>
            </Col>
            <Col sm="auto">
              <Form.Check id="isUrl" type="checkbox" checked={isUrl} onChange={handleChange} className="valueField" label="Hyperlink?" />
            </Col>
            {isUrl ? (
              <Col sm="auto">
                <Form.Control id="hlTitle" type="input" defaultValue={hlTitle} className="valueField" placeholder="Enter Hyperlink Title" />
              </Col>
            ) : (
              <></>
            )}
          </BSContainer>
        ) : props.type === "blockSpacer" ? (
          <BSContainer id="spacerContain" className="py-2 d-none">
            <Col sm="auto">
              <Form.Control id="fieldmappath" type="input" value="spacerBlockValue" className="valueField mb-1" />
            </Col>
          </BSContainer>
        ) : (
          <></>
        )}

        <Col sm="1">
          <Button type="button" onClick={() => props.removeMappingInput(props.id, props.blockId)} className="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </Button>
        </Col>
      </Row>
      {freeFormSelectedOption === "-1" && props.type === "blockFreeForm" ? <></> : <hr />}
    </>
  );
};
