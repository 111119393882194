import React, { useEffect } from "react";

export const InformationalBlock = (props) => {
  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For InformationalBlock", props.id)
  }

  let tblData = [];
  props.mapping.forEach((col) => {
    tblData.push({ [col[1].fieldName]: col[1].fieldmappath });
  });

  let blockData = {};
  const blockArr = [];

  if (props.content !== "" && props.content !== undefined) {
    const arrObj = Object.entries(props.content);
    const blockContent = arrObj.find((c) => c[0] === String(props.dataSource));
    if (blockContent !== undefined) {
      tblData.forEach((td) => {
        const curValue = Object.values(td)[0];
        const curKey = Object.keys(td)[0];
        blockData[curKey] = blockContent[1][curValue];
        blockArr.push(blockData);
        blockData = {};
      });
    } else {
      console.warn("Invalid mapping for", props.blockName);
    }
  }

  let details = blockArr.length === 0 ? props.mapping : blockArr;

  let contentToDisplay = [];
  let childElements = [];
  details.forEach((detail, index) => {
    const inx = index + 1;

    let content;
    if (blockArr.length !== 0) {
      let value = Object.values(detail)[0];
      if (value !== undefined) {
        if (value.includes(".jpg") || value.includes(".png") || value.includes(".jpeg")) {
          content = <img style={{ maxWidth: "100%", height: "auto" }} src={value} />;
        } else if (value.includes("://")) {
          let url;
          try {
            url = new URL(value);
          } catch (ex) {
            console.warn("Invalid URL for informational", value, ex);
            url = value;
          }
          content = (
            <a href={url} rel="noopener noreferrer" target="_blank">
              {value}
            </a>
          );
        } else {
          content = value;
        }
      }
    }

    childElements.push(
      <div key={inx + "_child"} className="row">
        <p className="font-weight-bold">{blockArr.length !== 0 ? Object.keys(detail)[0] : detail.name}</p>
        <p>{blockArr.length !== 0 ? content : ""}</p>
      </div>
    );
    if (inx % 2 === 0) {
      contentToDisplay.push(
        <div key={inx + "_parent"} className="col">
          {childElements}
        </div>
      );
      childElements = [];
    } else if (inx === blockArr.length) {
      contentToDisplay.push(
        <div key={inx + "_parent"} className="col">
          {childElements}
        </div>
      );
      childElements = [];
    }
  });

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  });

  return (
    <div id={props.keyId} className="card mt-2">
      <div className="card-header font-weight-bold">{props.blockName}</div>
      <div className="container">
        <div className="row">{contentToDisplay}</div>
      </div>
    </div>
  );
};
