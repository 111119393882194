class UrlHelper {
    static extractRoute(path) {
        if (!path) {
            return path;
        }
        const questionMark = path.indexOf("?");
        if (questionMark < 0) {
            return path;
        }

        return path.substr(0, questionMark);
    }
};

export default UrlHelper;