import React, { useState, useRef, useMemo } from "react";

import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";

export const TableBlock = (props) => {
  const gridRef = useRef();

  function LinkComponent(props) {
    let content = "";
    let url;
    try {
      url = new URL(props.value);
    } catch (ex) {
      console.warn("Invalid URL for table", props.value, ex);
      url = props.value;
    }
    content = (
      <a href={url} rel="noopener noreferrer" target="_blank">
        {props.data.hlTitle === undefined ? props.value : props.data.hlTitle}
      </a>
    );
    return content;
  }

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For TableBlock", props.id);
  }

  let tblColumns = [];
  props.mapping.forEach((col) => {
    if (col[1].isUrl) {
      tblColumns.push({
        cellStyle: { textAlign: col[1].alignmentInput },
        field: col[1].columnName,
        flex: 1,
        minWidth: 200,
        wrapText: true,
        autoHeight: true,
        cellRenderer: LinkComponent,
      });
    } else {
      tblColumns.push({ cellStyle: { textAlign: col[1].alignmentInput }, field: col[1].columnName, flex: 1, minWidth: 200, wrapText: true, autoHeight: true });
    }
  });

  let tblData = [];
  props.mapping.forEach((col) => {
    tblData.push({ [col[1].columnName]: col[1].fieldmappath });
    if (col[1].isUrl) {
      tblData.push({ ["hlTitle"]: col[1].hlTitle });
    }
  });

  let blockData = {};
  const blockArr = [];

  if (props.content !== "" && props.content !== undefined) {
    const arrObj = Object.entries(props.content);
    const blockContent = arrObj.find((c) => c[0] === String(props.dataSource));
    if (blockContent !== undefined) {
      blockContent[1].forEach((bc) => {
        tblData.forEach((td) => {
          const curValue = Object.values(td)[0];
          const curKey = Object.keys(td)[0];
          blockData[curKey] = bc[curValue];
        });
        blockArr.push(blockData);
        blockData = {};
      });
    } else {
      console.warn("Invalid mapping for", props.blockName);
    }
  }

  const [columnDefs, setColumnDefs] = useState([tblColumns]);

  if (JSON.stringify(columnDefs) !== JSON.stringify(tblColumns)) {
    setColumnDefs(tblColumns);
  }

  const [rowData, setRowData] = useState([blockArr]);

  if (JSON.stringify(rowData) !== JSON.stringify(blockArr)) {
    setRowData(blockArr);
  }

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 100,
    }),
    []
  );

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  });

  return (
    <div id={props.keyId} className="card mt-2">
      <div className="card-header font-weight-bold">{props.blockName}</div>
      <div style={{ height: "100%", width: "100%" }} className="">
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div style={{ flexGrow: "1" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                className="ag-theme-alpine"
                animateRows="true"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                rowSelection="multiple"
                suppressRowClickSelection="true"
                domLayout="autoHeight"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
